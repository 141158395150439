
  import { Component, Vue } from 'vue-property-decorator'
  import { GPdf } from '@/components/dataTables/PDF/GPdf'
  import jsPDF from 'jspdf'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'
  import { plainToInstance } from 'class-transformer'
  import { Reserve, Stock } from '@/entities/sales'
  import dayjs from 'dayjs'
  import { DealAutoAttribute } from '@/entities/public'
  import { Payment } from '@/entities/finance'
  import { fixPrice, fixThousands } from '@/utils/general'
  import { Deal } from '@/entities/crm'

@Component
  export default class reservePDF extends GPdf {
  declare options: LinkedCellOptions
  loading = false;
  imgUrl = '@/assets/companyLogo/logo.jpg'; // URL de la imagen
  reserve = plainToInstance(Reserve, {})
  stock = plainToInstance(Stock, {})
  autoAttributes = [plainToInstance(DealAutoAttribute, {})]
  order = plainToInstance(Payment, {})
  payments = []
  deal = plainToInstance(Deal, {})
  mileage = 0
  disabledPdf = false
  carPhotoBase64: string | null = null;

  system: string
  process: any

  async mounted () {
    const { item: { id } } = this

    const check = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { process: { table_name: { _eq: 'reserve' } } },
          { status: { status: { name: { _in: ['pending', 'to_update'] } } } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })
    const reserve = await this.fetchData({
      query: { name: 'fetch', model: 'Reserve', params: { id } },
      force: true,
    })

    this.disabledPdf = Boolean(check.length) || Boolean(reserve.closingReason)
  }

  async getReserveInfo () {
    const { item: { id } } = this

    const system = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
    })
    this.system = system.companyName
    this.reserve = await this.fetchData({
      query: { name: 'fetch', model: 'Reserve', params: { id } },
      force: true,
    })
    this.stock = await this.fetchData({
      query: { name: 'fetch', model: 'Stock', params: { id: this.reserve.saleOrder.deal.stock.id } },
      force: true,
    })
    this.autoAttributes = await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: { id_deal: { _eq: this.reserve.saleOrder.deal.id } },
      force: true,
    })

    const mileage = await this.fetchData({
      query: { name: 'find', model: 'AttributeView' },
      filter: { id_auto: { _eq: this.item.saleOrder.deal.auto.id }, component: { slug: { _eq: 'mileage' } } },
    })

    this.mileage = mileage.sort((prev, next) => {
      return next.value - prev.value
    })[0].value

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'reserve' } },
    })

    const order = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { id_process: { _eq: process?.[0].id } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })

    const fileParameter = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'stock' } } },
          { file_type: { name: { _eq: 'photo' } } },
          { name: { _eq: 'right_front' } },
        ],
      },
    })
    const fileProcess = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.reserve.saleOrder.deal.stock.id } },
          { id_file_parameter: { _eq: fileParameter[0].id } },
        ],
      },
      force: true,
    })

    this.carPhotoBase64 = await this.loadImage(fileProcess[0]?.file?.largeUrl)

    this.order = order[0]
    this.payments = order

    this.deal = await this.fetchData({
      query: { name: 'fetch', model: 'Deal', params: { id: this.reserve.saleOrder.deal.id } },
      force: true,
    })

    this.stock.transferCost = await this.calculateTransfer(this.stock.id)
  }

  async generatePDF () {
    this.loading = true
    await this.getReserveInfo()
    const pdfContent = this.$refs.pdfContent
    const element = pdfContent instanceof HTMLElement ? pdfContent : (pdfContent as Vue).$el as HTMLElement

    const JsPDF = jsPDF
    const doc = new JsPDF('p', 'mm', 'a4')

    // Coordenadas iniciales
    const x = 10
    const y = 20

    doc.setLineWidth(400)

    // Llenar el PDF con el contenido
    await this.page1(doc, y, x)

    // Convertir el contenido del HTML a texto en el PDF
    this.addHtmlContent(doc, element, x, y)
  }

  addSubtitle (doc: jsPDF, x, y) {
    const documentId = `N°${dayjs(this.reserve.createdAt).format('YYYY')}${'0'.repeat(9 - String(this.reserve.id).length)}${this.reserve.id}`
    const hourText = `Hora: ${dayjs(this.reserve.createdAt).format('HH:mm')}`
    const dateText = `Fecha: ${dayjs(this.reserve.createdAt).format('DD/MM/YYYY')}`
    doc.text(documentId, x, y)
    doc.text(hourText, this.getCenterWidth(doc, hourText), y)
    doc.text(dateText, this.getAlignEnd(doc, dateText), y)
    y += 5
    y = this.separator(doc, y)
    return y
  }

  async addCarData (doc: jsPDF, x, y) {
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'enablement' } },
      force: true,
    }))[0]
    const enablement = ((await this.fetchData({
      query: { name: 'find', model: 'Enablement' },
      filter: { stock: { id: { _eq: this.stock.id } } },
      force: true,
    })).sort((prev, next) => prev.id - next.id))[0]
    const component = (await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: { slug: { _eq: 'mileage' } },
      force: true,
    }))[0]
    const mileage = (await this.fetchData({
      query: { name: 'find', model: 'AttributeView' },
      filter: {
        _and: [
          { id_process: { _eq: process.id } },
          { id_process_record: { _eq: enablement.id } },
          { id_component: { _eq: component.id } },
        ],
      },
    }))[0]
    const auto = this.deal.auto
    const price = this.reserve?.saleOrder?.saleOrderItems?.filter(item => item.type.name === 'product')?.[0]?.amount

    y = this.addSectionTitle(doc, x, y, 'Datos del vehículo')
    if (this.carPhotoBase64) {
      doc.addImage(this.carPhotoBase64, 'JPEG', x, y - 3, 50, 35)
    }
    y = this.insertList(doc, x + (this.carPhotoBase64 ? 55 : 0), y, [
        'Patente',
        'Año',
        'Marca',
        'Modelo',
        'Kilometraje',
        'Precio de compra',
      ],
      [
        auto.registrationPlate + '|bold',
        String(auto.version.year.id),
        auto.version.version.model.brand.name,
        auto.version.version.model.name + ' ' + auto.version.version.name + '|bold',
        `${fixThousands(mileage.value)} kms`,
        fixPrice(price) + '|bold',
      ])

    y = this.separator(doc, y)

    return y
  }

  async addSigner (doc: jsPDF, x, y) {
    const client = this.reserve.saleOrder.deal.lead.client
    const address = (await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { id_person: { _eq: client.id } },
    }))?.[0]
    const addressString = address ? `${address.address?.streetName} ${address.address?.streetNumber}, ${address.address?.city?.name}`.toUpperCase() : undefined
    y = this.addSectionTitle(doc, x, y, 'Cliente')
    y = this.insertList(doc, x, y,
      [
        'Nombres',
        'Apellidos',
        'Rut',
        'Teléfono',
        client.email?.personal || client.email?.work ? 'Mail' : undefined,
        addressString ? 'Dirección' : undefined,
      ].filter(item => item),
      [
        `${client.firstName?.split(' ')?.[0] || ''} ${client.secondName?.split(' ')?.[0] || ''}`.toUpperCase() + '|bold',
        `${client.surname?.split(' ')?.[0] || ''} ${client.secondSurname?.split(' ')?.[0] || ''}`.toUpperCase() + '|bold',
        client.uid,
        client.mainPhone,
        client.email?.personal || client.email?.work || undefined,
        addressString,
      ].filter(item => item),
    )
    y = this.separator(doc, y)
    return y
  }

  addBriefing (doc: jsPDF, x, y) {
    const payments = this.payments.map(payment => {
      return {
        legend: `Abono ${payment.createdAt.format('DD/MM/YYYY')}`,
        amount: payment.amount,
      }
    })
    const givenDiscount = this.reserve.saleOrder.saleOrderItems.filter(item => item.type.name === 'discount')?.[0]?.amount || 0 + this.reserve.saleOrder.saleOrderItems.filter(item => item.type.name === 'post_financing_discount')?.[0]?.amount || 0
    let totalPayments = 0
    payments.forEach(payment => totalPayments += payment.amount)
    y = this.addSectionTitle(doc, x, y, 'Resumen de la transacción')
    y = this.insertList(doc, x, y,
      [
        'Fecha acordada de entrega',
        'Precio de venta',
        givenDiscount ? 'Descuento otorgado' : undefined,
        'Costo de traspaso',
        'Total (venta + traspaso)',
        'Total abonos',
        ...payments.map(payment => payment.legend),
        'Saldo por cubrir',
      ].filter(item => item),
      [
        this.reserve.saleOrder.deliveryDate.format('DD/MM/YYYY') + '|bold', // Fecha acordada de entrega
        fixPrice(this.deal.price), // Precio de venta
        givenDiscount !== 0 ? fixPrice(Math.abs(givenDiscount)) : undefined, // Descuento
        fixPrice(this.stock.transferCost), // Costo de traspaso
        fixPrice(this.deal.price + this.stock.transferCost + givenDiscount) + '|bold', // Total
        fixPrice(totalPayments), // Total Abonos
        ...payments.map(payment => fixPrice(payment.amount)), // Payment legends
        fixPrice(this.deal.price + this.stock.transferCost + givenDiscount - totalPayments) + '|bold', // Saldo por cubrir
      ].filter(item => item),
    )
    y = this.separator(doc, y)
    return y
  }

  async addWarranty (doc, x, y) {
    if (y > doc.internal.pageSize.getHeight() - 60) {
      y = this.newPage(doc, y)
      await this.addClosingSection(doc)
    }
    const title = 'Garantías de $system'.split('$system').join(this.system)
    y = this.addSectionTitle(doc, x, y, title)
    y = this.insertList(doc, x, y, ['Reembolso del abono si:'], [])
    y = this.insertNumberedList(doc, x, y, this.process.config.reserve_letter.warranty.list)
    y = this.separator(doc, y)
    return y
  }

  async addCompromise (doc, x, y) {
    if (y > doc.internal.pageSize.getHeight() - 60) {
      y = this.newPage(doc, y)
      await this.addClosingSection(doc)
    }
    y = this.addSectionTitle(doc, x, y, 'Compromisos')
    y = this.addParagraph(doc, x, y, this.process.config.reserve_letter.compromise.text)
    y = this.separator(doc, y)
    return y
  }

  addSignSection (doc: jsPDF, y) {
    const declaration = 'Firma y acepta las condiciones de reserva'
    const clientName = this.reserve.saleOrder.deal.lead.client.fullName
    const clientUid = this.reserve.saleOrder.deal.lead.client.uid

    let height = (y + doc.internal.pageSize.getHeight() - 30) / 2 - 20

    doc.setDrawColor('#0033A5')
    doc.line(60, height, doc.internal.pageSize.getWidth() - 60, height)
    height += 5
    doc.text(declaration, this.getCenterWidth(doc, declaration), height)
    height += 5
    doc.setFont(undefined, 'bold')
    doc.text(clientName, this.getCenterWidth(doc, clientName), height)
    height += 5
    doc.text(clientUid, this.getCenterWidth(doc, clientUid), height)
    height += 7
    return height
  }

  async addClosingSection (doc: jsPDF) {
    const executive = this.reserve.saleOrder.deal.lead.executive
    const clientService = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })
    this.addClosing(doc, [
        'Asesor comercial:',
        `${executive.person.firstName} ${executive.person.surname}`,
        `Tel: ${executive.person.phoneWork || 'No informado'}`,
        executive.person.email.work || 'Email no informado',
      ],
      [
        'Atención al cliente:',
        'Tel: ' + clientService.phoneWork,
      ],
    )
  }

  async page1 (doc, y, x) {
    this.process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'reserve' } },
      force: true,
    }))[0]

    const system = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })

    const imageData = await this.loadImage(system.photo)
    const pageWidth = doc.internal.pageSize.getWidth()

    if (imageData) {
      const type = system.photo.split('.').pop()
      doc.addImage(imageData, type.includes('png') ? 'PNG' : 'JPEG', (pageWidth / 2 - 30), 10, 60, 6)
      y += 5
    } else {
      y -= 10
    }

    this.footer(doc)
    this.addClosingSection(doc)
    y = this.separator(doc, y)
    y = this.addTitle(doc, y, 'FORMALIZACIÓN DE RESERVA')
    y = this.addSubtitle(doc, x, y)
    y = await this.addCarData(doc, x, y)
    y = await this.addSigner(doc, x, y)
    y = this.addBriefing(doc, x, y)
    y = await this.addWarranty(doc, x, y)
    y = await this.addCompromise(doc, x, y)
    this.addSignSection(doc, y)
  }

  addHtmlContent (doc: jsPDF, element: HTMLElement, x: number, y: number) {
    doc.html(element, {
      callback: doc => {
        this.loading = false
        doc.save(`reserva ${this.reserve.saleOrder.deal.lead.client.shortName} ${this.reserve.saleOrder.deal.lead.client.uid}.pdf`)
      },
      x,
      y, // Ajustar la posición y según la altura de la imagen y el texto
      html2canvas: { scale: 0.5 }, // Ajustar el escalado si es necesario
    })
  }

  get action () {
    return this.options?.action
  }

  get icon () {
    const { action, item } = this

    return action?.icon || item?.icon
  }

  get iconColor () {
    const { options } = this

    return options?.action?.iconColor || 'white'
  }

  get color () {
    const { action, item } = this

    return action?.color || item?.color
  }

  get tooltip () {
    const { options, item } = this

    return options?.tooltip || item?.tooltip || item?.name || item?.contact?.name
  }

  get disabledButton () {
    const { item } = this

    return !item?.id
  }
  }
